html {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 100%;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

body {
  color: #666;
  line-height: 1.333;
}

h1 {
  margin-top: 0;
  font-size: 3.998em;
}

h1 {font-size: 2.827em;}

h2 {font-size: 1.999em;}

h3 {font-size: 1.7em;}

h4 {font-size: 1.414em;}

small, .font_small {font-size: 0.707em;}
