/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/

/* whites and blacks */
$black :                  #000;
$black-forest:            #1A1A1A;
$gray-darkest:            #25282A;
$gray:                    #7F8283;
$dusty-gray:              #929292;
$white:                   #FFF;
$ghost:                   transparent;




/* greys - lightest to darkest */
$wild-sand:               #F6F6F6;
$concrete:                #F2F2F2;
$alto:                    #CFCFCF;
$silver:                  #CCCCCC;
$dove-gray:			      #666666;
$mine-shaft:              #333333;



$fountain-blue:  		  #52c0b0;