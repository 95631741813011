.column-page {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.solves {
		display: flex;
		flex-direction: column;
		padding-right: 30px;

		.solve-heading-group {
			display: flex;
    		justify-content: space-between;

    		.open-mix {
    			text-transform: uppercase;
			    font-size: 12px;
			    margin: 20px;
    		}

			.solve-heading {
				font-size: 28px;
				font-weight: bold;
				color: $black;
				margin-bottom: 30px;

				.solve-sub-heading {
					font-size: 14px;
					color: $dove-gray;
					font-weight: normal;
					font-style: italic;
				}
			}
		}

		.solve-ctf-heading {
			font-size: 18px;
			font-weight: bold;
		}

		.solve-group {
			display: flex;
			flex-direction: column;
			padding: 0px 0px 20px 0px;

			.solve-boxes {
				display: flex;
				flex-wrap: wrap;
			}

			.solve-writeup {
				color: $black;

				.solve-box {
					display: flex;
					flex-direction: column;
					height: 90px;
					min-width: 60px;
					background-color: $white;
					margin: 10px 20px 10px 0px;
					justify-content: center;
	    			align-items: center;
	    			border: 1px solid $silver;

	    			&:hover {
	    				-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
						-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
						box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
	    			}

	    			.solve-category {
	    				color: $contrast-color;
	    			}

	    			.solve-title, .solve-category {
	    				text-transform: uppercase;
	    				font-size: 12px;
						margin: 0px 5px;
						height: 30px;
	    				max-width: 130px;
	    				text-align: center;
						display: flex;
						align-items: center;
	    			}

	    			.solve-points {
	    				font-size: 20px;
	    				font-weight: bold;
	    			}
				}
			}
		}
	}
}

.writeup-container { 
	background-color: white;
	padding: 30px;
	border-left: 6px solid $contrast-color;
	display: flex;
	flex-direction: column-reverse;

	.write-heros {
		display: flex;
		flex-direction: column;
    	align-self: flex-end;

		.hero-title {
		    display: flex;
		    align-items: center;
		    font-size: 12px;
		    margin-bottom: 5px;
		    text-transform: uppercase;
		    justify-content: center;
		    color: $contrast-color;
		}

		.photo-links {
			&:hover {
				cursor: pointer;
				
				.participant-photos {
					height: 40px;
				    transition: all 150ms linear; /* vendorless fallback */
				    -o-transition: all 150ms linear; /* opera */
				    -ms-transition: all 150ms linear; /* IE 10 */
				    -moz-transition: all 150ms linear; /* Firefox */
				    -webkit-transition: all 150ms linear; /*safari and chrome */
				}
			}

			.participant-photos {
				height: 35px;
				padding: 0px 3px;
				border-radius: 50%
			}
		}
	}

	.markdown {
		.image-centered {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 12px;
		    font-weight: bold;
		    font-style: italic;

			img {
				max-width: 100%;
			}
		}

		h2 {
			margin-bottom: 1rem;
	    	color: #52c0b0;
		}

		pre {
			background-color: $wild-sand !important;
			padding: 10px !important;	
		    overflow: hidden !important;
		    overflow-x: scroll !important;
		}

		code {
			background-color: #fff1f3;
	    	padding: 2px;
		}
	}
}




@media screen and (min-width: 200px){
	.graph {
		canvas {
		    height: 240px;
		    width: 340px;
		}
	}
}

@media screen and (min-width: 600px){
	.graph {
		canvas {
		    height: 100%;
		    width: auto;
		    margin: 0 auto;
		}
	}
}
