@import '../base/variables';

.navbar {
	background-color: $white;

	.navbar-right {
		display: flex;
		flex-direction: column;
		align-items: center;

		.ctf-label {
			font-size: 16px;
			margin-top: 10px;
			font-weight: 400;
			color: $black;
			text-transform: uppercase;
			letter-spacing: 0.5px;
		}
	}
}

.secondary-nav {
	background-color: $gray-darkest;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: 6px solid $contrast-color;

	li.nav-item {
		align-self: center;

	}

	a.nav-link {
		color: $concrete;
		font-weight: 100;
		font-size: calc(1vw + 1vh);
		align-items: center;
		word-wrap: break-word;
		text-align: center;
		padding: .5rem 2rem .5rem 0rem;

	    transition: all 150ms linear; /* vendorless fallback */
	    -o-transition: all 150ms linear; /* opera */
	    -ms-transition: all 150ms linear; /* IE 10 */
	    -moz-transition: all 150ms linear; /* Firefox */
	    -webkit-transition: all 150ms linear; /*safari and chrome */

		&:hover {
			letter-spacing: 1px;
			color: white;
			font-weight: bold;
			cursor: pointer;
		}
	}
}

a:hover {
	text-decoration: none !important;
}

ul.nav {
	flex-wrap: nowrap;
}

@media screen and (min-width: 200px){
	.main-logo {
		width: 150px;
	}
	.cat-logo {
		width: 40px;
	}
}

@media screen and (min-width: 448px){
	.main-logo {
		width: 250px;
	}
	.cat-logo {
		width: 70px;
	}
}
@media screen and (min-width: 768px){
	.main-logo {
		width: 350px;
	}
	.cat-logo {
		width: 70px;
	}
}

@media screen and (min-width: 1200px) {
	.secondary-nav {
		a.nav-link {
			font-size: 18px;
		}
	}
}