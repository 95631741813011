.result-box {
	display: flex;
	flex-direction: column;
	background-color: $white;
	border-top: 1px solid $alto;
	border-bottom: 1px solid $alto;
	border-right: 1px solid $alto;
	border-left: 6px solid $contrast-color;
	margin-bottom: 25px;


	.box-content {
		padding: 15px;
	
		.event-title-bar {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			font-size: 24px;
			font-weight: bold;
			padding-bottom: 20px;

			.event-title {
				color: $mine-shaft;
			}

			.event-solves {
				color: $contrast-color;
			}
		}

		.event-description {
			padding-right: 75px;
		}
	}

	.bottom-panel {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-color: $wild-sand;
		padding: 5px 15px;
		align-items: center;
		color: $silver;
		height: 50px;

		.photo-links {
			&:hover {
				cursor: pointer;
				
				.participant-photos {
					height: 40px;
				    transition: all 150ms linear; /* vendorless fallback */
				    -o-transition: all 150ms linear; /* opera */
				    -ms-transition: all 150ms linear; /* IE 10 */
				    -moz-transition: all 150ms linear; /* Firefox */
				    -webkit-transition: all 150ms linear; /*safari and chrome */
				}
			}

			.participant-photos {
				height: 35px;
				padding: 0px 3px;
				border-radius: 50%
			}
		}
	}
}

